// import { Button } from "bootstrap";
import * as React from "react";

import "./routes.css";
import Ann from "../img/annWatkins.jpg";
import Sarah from "../img/sarahPantalone.jpeg";
// import Kristin from "../img/kristinAndrulonis.jpg";
// import Julie from "../img/julieAlmeida.jpeg";
import Randy from "../img/randy.jpg";

const aboutBackground = {
  backgroundColor: "#D0C88E",
};
const aboutContentBackground = {
  backgroundColor: "#E9E4D2",
};
const aboutUsTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};
const aboutText = {
  padding: ".5rem",
  marginLeft: "1rem",
};
const contactAnn = {
  // height: "35vh",
  width: "15vw",
  borderRadius: "5px",
  boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
  marginLeft: "1rem",
};
const aboutUsMemoriam = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};
const aboutUsRandy = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  // paddingTop: "1rem",
  paddingTop: "0rem",
  marginTop: "0rem",
};
const aboutFinalP = {
  padding: ".5rem",
  marginLeft: "1rem",
  marginBottom: "0rem",
};
function About() {
  return (
    <>
      <div style={aboutBackground}>
        <div className="container">
          <div style={aboutContentBackground}>
            <h1 style={aboutUsTitles}>About Us</h1>

            <h5 style={aboutText}>
              <strong>New Harmony Mission Statement</strong> <br />
              <small>
                To provide healing and improve the quality of life on the planet
                one person at a time
              </small>
            </h5>
            <p style={aboutText}>
              Our massage therapy is quite unlike any other type in that it
              corrects the underlying problems associated with chronic pain. We
              work with you to discover the cause and correct it, allowing you
              relief from pain that you have not been able to achieve with other
              forms of medicine and therapies. Our clients usually start
              experiencing relief after the first session with marked results by
              the third and may require maintenance sessions in order to
              maintain relief. In cases where relief does not require
              maintenance, many of our clients still return to achieve enhanced
              relaxation as well as physical and emotional well being.
            </p>

            <h5 style={aboutText}>
              <strong>Chronic Pain Breakthrough </strong> <br />
              <small>
                Our massage therapy is unlike any massage you've ever had
              </small>
            </h5>
            <p style={aboutText}>
              We work together with a special technique to create muscle balance
              and tissue health, relieving and correcting the underlying
              problems associated with most chronic pain. Our clients usually
              begin to get relief right away and by the third session it’s clear
              what the solution to your problem is. Though relief does not
              always require continued maintenance, many of our clients still
              return to achieve enhanced relaxation as well as physical and
              emotional well being.
              <a href="/therapies" target="_blank" rel="noreferrer">
                <p>Therapies we offer</p>
              </a>
            </p>

            <div className="row">
              <h3 style={aboutUsTitles}>Ann Watkins, MA</h3>
              <h5 style={aboutText}>Licensed Massage Therapist #000393</h5>
              <div className="col-4">
                <img src={Ann} style={contactAnn} alt="ann watkins" />
              </div>
              <div className="col-8">
                "Healing the Planet One Person at a Time." Honors in Psychology,
                MA in Dance Movement Therapy, Licensed Massage Therapist since
                1988, published in Massage Magazine. Trained in Lymphatic
                Drainage; Energy Work: shiatsu, acupressure, polarity;
                NeuroMuscular Reprogramming / NeuroKinetic Therapy; originator of
                (Neuro) ReactiveTriads™ Bodywork -a breakthrough in chronic pain
                relief (DVD).
              </div>
            </div>
            <h5 style={aboutText}>
              <strong>Mission Statement: </strong>
              <small>
                My mission is to inspire self-connection and self-
                responsibility; to promote self-healing and well-being; to
                enhance the quality of life on earth one person at a time.
              </small>
            </h5>

            <div className="row">
              <h1 style={aboutUsTitles}>Staff</h1>
              <a
                href="https://newharmonymassage.weebly.com/staff.html"
                target="_blank"
                rel="noreferrer"
              >
                <h5 style={aboutText}>
                  <small>
                    <strong>Our Licensed Massage Therapists ››</strong>
                  </small>
                </h5>
              </a>

              <div className="row">
                <h3 style={aboutUsTitles}>Sarah Pantalone MA LMT</h3>
                <div className="col-4">
                  <img src={Sarah} style={contactAnn} alt="Sarah Pantalone" />
                </div>
                <div className="col-8">
                  <p>
                    Sarah is a licensed massage therapist who offers
                    individually tailored sessions for each of her clients in
                    both CranioSacral therapy and postural corrective work
                    incorporating various osteopathic modalities, as well as
                    Neurolymphatic Reactive TriadsTM Bodywork for chronic pain
                    breakthrough. She is passionate about working with all of
                    her clients to help them on their journey of healing, as
                    well as women on their journey of pregnancy and restoration
                    of balance and healing postpartum.
                  </p>
                  <p>
                    Sarah is a graduate of Connecticut Center for Massage
                    Therapy where she received foundational training and has
                    since continued her studies in osteopathic modalities
                    enabling her to interface and work directly with the central
                    nervous system, the peripheral nerves and the organs of the
                    abdomen. She is now receiving training from Ann Watkins at
                    New Harmony Therapeutic Massage and Somatic Bodywork. in her
                    original Neurolymphatic Reactive TriadsTM Bodywork This
                    specialized training has enhanced Sarah’s abilities when
                    working with clients who have chronic pain for which
                    traditional treatments and therapies have not worked and who
                    have experienced trauma in any of a multitude of forms. She
                    also works with women contemplating pregnancy, who are
                    pregnant or who have ever been pregnant.
                  </p>
                  <p>
                    Sarah is taking some time away to support her daughter's
                    schooling. Please take advantage of Kristin's availability
                    and call for an appointment.
                  </p>
                </div>
              </div>

              {/* <div className="row">
                <h3 style={aboutUsTitles}>Kristin Andrulonis - Intern</h3>
                <div className="col-6">
                  <img
                    src={Kristin}
                    style={contactAnn}
                    alt="Kristin Andrulonis"
                  />
                </div>
                <div className="col-6">
                  <p>
                    Meet our newest addition. Kristin will be interning at New
                    Harmony Therapeutic Massage and Somatic Body Work for the
                    months of March, April 2022 on Thursdays from 9-3 with some
                    flexibility. Other days may be added later on.
                  </p>
                  <p>
                    She is currently studying at Bancroft School of Massage in
                    Massachusetts and will be fulfilling her internship hours
                    required for graduation.
                  </p>
                  <p>
                    {" "}
                    Treat yourself to a well executed, customized, relaxing all
                    over body massage and reap the benefits for only $45.00 per
                    hour. Call 860-599-4533 and mention Kristin.
                  </p>
                  <p>
                    Kristin is not allowed to receive payment or tips as per
                    school rules.
                  </p>
                  <p>All proceeds will cover expenses at New Harmony.</p>
                  <p>
                    Kristin is also taking courses in Conscious Anatomy™ Bones
                    Planes and Directions and Reactive Triadstm Body Work. She
                    has gotten such good reviews it looks like she will work
                    here at least one day a week once she is licensed. Of course
                    the fee per hour will increase most likely to 80 or 85 and
                    hour.
                  </p>
                  <p>
                    Please contact me at 860-599-4533 to set up a session with
                    Kristin. You won't be disappointed!
                  </p>
                </div>
              </div> */}

              {/* <div className="row">
                <h3 style={aboutUsTitles}>
                  Julie Almeida Facial Care Provider
                </h3>
                <div className="col-6">
                  <img src={Julie} style={contactAnn} alt="Julie Almeida" />
                </div>
                <div className="col-6">
                  <p>
                    At New Harmony I will provide a relaxing and beneficial
                    experience using a top brand product line; a delightful
                    massage is included and a calming Charcoal Rescue Masque
                    that highlights optimal cleansing and hydration. This
                    Dermalogica masque exfoliates and absorbs impurities from
                    all skin types and conditions. In combination with the
                    Intense Moisture Cream, you can expect brighter skin,
                    refined pores, and a reduction in the appearance of fine
                    lines.
                  </p>
                  <p>
                    Julie graduated from New London Academy in 1986 where she
                    studied the beautification of skin. She has periodically
                    given facials at Mystic Clipper hair Salon and taught
                    procedures at Ella Grasso Tech High School from 2004-2016.
                  </p>
                  <p>
                    My Dermalogica All in One Facial benefits all ages. Call or
                    text Julie at 860-460-9076. Let her know you're planning to
                    have your session at New Harmony Therapeutic Massage and
                    Somatic Body Work in Pawcatuck, CT.
                  </p>
                </div>
              </div> */}
            </div>

            <div className="row">
              <h3 style={aboutUsMemoriam}>In Memoriam</h3>
              <h3 style={aboutUsRandy}>Randy Shepherd, CBW</h3>
              <h5 style={aboutText}>Licensed Massage Therapist #002742</h5>
              <div className="col-4">
                <img src={Randy} style={contactAnn} alt="randy shepherd" />
              </div>
              <div className="col-8">
                A graduate of Alive and Well! Institute of Conscious BodyWork,
                in Corte Madera, Ca. in 1999. Completed their “Conscious
                BodyWorker” program with over 600 hours of instruction and hands
                on training in both structural and energetic approaches. He
                moved from the San Francisco bay area in July of 1999, and has
                worked as a bodyworker since that time.
              </div>
            </div>
            <h5 style={aboutText}>
              <strong>Mission Statement: </strong>
              <small>
                I am a professional bodyworker dedicated to providing
                therapeutic, life enhancing bodywork. By being sensitive to the
                needs of others and by using various techniques and skills, I
                assist them in becoming the best they choose to be.
              </small>
            </h5>
            <p style={aboutFinalP}>
              Personal: I touch lives in a positive uplifting and healing
              manner. This I do with honesty, integrity and love, encouraging
              the best to shine through.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
