// import { Button } from "bootstrap";
import * as React from "react";
import "./routes.css";
import greenReactiveTriadsBodyworkLogo from "../img/greenReactiveTriadsBodyworkLogo.png";

const therapiesPad = {
  padding: "1.5rem",
};
const therapiesBackground = {
  backgroundColor: "#D0C88E",
  // padding: "1rem"
};
const therapiesContentBackground = {
  backgroundColor: "#E9E4D2",
};
const therapiesTitle = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
  textAlign: "center",
};

function Therapies() {
  return (
    <>
      <div style={therapiesBackground}>
        <div className="container" style={therapiesContentBackground}>
          <h1 style={therapiesTitle}>Therapies we offer</h1>
          <p>
            <h3>
              Our therapies offer a break through in chronic pain relief through
              years of professional education and practice.
            </h3>
            <strong>
              Our massage therapy is unlike any massage you've ever had.
            </strong>
            We work together with a special technique to create muscle balance
            and tissue health, relieving and correcting the underlying problems
            associated with most chronic pain. Our clients usually begin to get
            relief right away and by the third session it’s clear what the
            solution to your problem is. Though relief does not always require
            continued maintenance, many of our clients still return to achieve
            enhanced relaxation as well as physical and emotional well being.
          </p>
          <p>
            <strong>
              We have studied many therapies over the years and have integrated
              into our repertoires what we've found to be most useful from each.
            </strong>
            We specialize in ReactiveTriads™ Body Work, a postural corrective
            muscle balancing technique originated by Ann Watkins based in NMR.
          </p>
          <p>
            Reactive Triads™ Body Work is based in NeuroMuscular Reprogramming,
            originated by Jocelyn Olivier in the ’70s. Reactive Triads™ Body
            Work solves problems that were unsolvable using the super effective
            NMR. See the classes tab for more information. Also, check out{" "}
            <a
              href="https://www.bodymindspiritanatomy.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.bodymindspiritanatomy.com/
            </a>{" "}
            for course information and other freebies
          </p>
          <p>
            Additional therapies are integrated into any given session as
            needed. If you are only looking for a particular therapy listed
            below you may want to contact a practitioner who specializes in it
            alone; however, our clients have most often found our eclectic blend
            to be more effective than one specific modality.
          </p>

          {/* <a href="https://www.bodymindspiritanatomy.com/">
            <h2>Purchase Training</h2>
          </a> */}

          <h2 style={therapiesTitle}>Therapies List:</h2>

          <div className="container">
            <div
              className="accordion"
              id="accordionExample"
              style={therapiesPad}
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <strong>1. Postural corrective bodywork</strong>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Integrates our original education with our continuing
                      education and our original work.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <strong>
                      2. NeuroMuscular Reprogramming® (NMR) | NeuroKinetic
                      Therapy (NKT)
                    </strong>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Originated by Jocelyn Olivier in the 1970s, NMR is a
                      muscle-balancing technique that is super effective on
                      virtually healthy bodies. You might also find it called
                      NKT. It uses muscle testing to determine if one muscle is
                      inhibiting another muscle from firing. It uncovers muscles
                      that are working too hard and muscles that are not working
                      hard enough. With retest it gets the underworking muscles
                      reconnected to the brain and allows the overworking
                      muscles to relax. The result is an increased range of
                      motion and decreased pain and a building of support in
                      virtually healthy muscles. Other intervention is needed
                      for unhealthy or very compromised muscles. See Reactive
                      Triads™ Body Work.
                    </p>
                    <p>
                      Muscles need to be able to contract and relax as needed
                      whether they are working isometrically or in complement to
                      one another. With this muscle balancing technique, the
                      muscles learn to appropriately activate and deactivate;
                      pain is relieved, posture improves, range of motion
                      increases, and the ability to relax is enhanced. This
                      often takes more than one session.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <strong>3. Reactive Triads™ | Reactive Triads™</strong>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <img
                      src={greenReactiveTriadsBodyworkLogo}
                      alt="greenReactiveTriadsBodyworkLogo"
                    />
                    <p>
                      Originated and developed by Ann Watkins. Reactive Triads™
                      Bodywork brings together the muscle system, the lymphatic
                      system, and the joint system. It is truly a breakthrough
                      in chronic pain relief and is great for relieving pain in
                      healthy muscles, unhealthy muscles, and highly compromised
                      muscles.
                    </p>
                    <p>
                      RT™B uncovers improperly firing muscles by activating
                      three elements including the lymphatic system, the joint
                      system, and the muscle system. It deactivates compensation
                      patterns in the muscles causing pain, achiness, and
                      discomfort. It reprograms the body’s response to shock,
                      improves lymphatic flow, frees up the visceral, decreases
                      swelling, and alleviates muscle pain. Reactive Triads™
                      brings relief to muscle pain, nerve pain, limited range of
                      motion, and inflammation and also initiates long lasting
                      postural correction. Reactive Triads™, originated in 2001
                      (see Ann’s DVD, published in 2006-2007, Reactive Triads™ a
                      breakthrough in chronic pain) changes the NMR/ NKT
                      paradigm from pairs to triads and involves more than just
                      the muscle system.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <strong>4. Relaxation Massage</strong>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Relaxation Massage is greatly enhanced by the integration
                      of Reactive Triads ™Body Work as it teaches the muscles
                      how to relax. When relaxation is specified we will
                      typically use more Swedish, Pressure point (acupressure,
                      shiatsu), and other more typically familiar forms of
                      massage. If we find a muscle area that is not responding
                      to other more typical modalities we will ask you if you
                      mind if we do some testing. If you specifically do not
                      want the testing to be included we will honor that
                      request. Typically muscles relax more deeply with muscle
                      balancing where other modalities don’t do the trick.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <strong>5. Energy Work</strong>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Energy work may include Acupressure, Shiatsu, Brain Gym,
                      Energy Balancing, work with chakras/Reiki, or organ
                      massage- as done in Lymphatic Drainage, and is typically
                      integrated into the whole massage as needed. Although we
                      may do a full session if requested with one of these
                      modalities, we do not specialize in them and, if that is
                      what you are specifically interested in, you may want to
                      consider going to a practitioner that does. Our clients
                      rarely find a single modality to be more effective than
                      our integrated approach.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <strong>6. Lymphatic Drainage</strong>
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      This is a technique used to reduce inflammation in the
                      interstitial tissues. The lymphatic system cleanses a
                      third of your blood at a time. It transports molecules too
                      large for the circulatory system to places in the body in
                      need of healing. In the case of toxins it transports to
                      the excretory system. Mostly a light touch technique,
                      because most lymph fluid is located just under the skin,
                      it increases the volume that circulates through the body
                      by about 20x. Bruno Chikly’s method of lymphatic drainage
                      was originally developed to deal with lymphodema after
                      cancer surgery. Ann Watkins has worked with pre and post
                      cancer patients in relieving lymphodema and uses it for
                      general health in many of her clients as needed. Reactive
                      Triads incorporates lymphatic drainage into its method in
                      order to speed the process of reducing inflammation with
                      the action of the muscles
                      <a
                        // href="/store#reactivetriadsdvd"
                        href="/store"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (See Reactive Triads DVD).
                      </a>
                      This is especially important if you have Fibromyalgia or
                      other chronic inflammation – migraines are often
                      accompanied by chronic, residual inflammation. You will be
                      less sore or no longer sore.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <strong>
                      7. Conscious Anatomy™ Movement for Body Mind and Spirit™
                    </strong>
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Discover a new experience through the language of
                      movement, sound, imagery, breath and bring better health
                      to your body. Sound vibration improves the health of your
                      inner organs, expands your breath, and calms your mind.
                      Increasing your movement repertoire, as per the Kestenberg
                      Movement Profile, expands your life view and protects
                      against dementia/alzheimers. Start early. Make room for
                      more vibrancy. Gain a new perspective, stretch and
                      strengthen your body, re-charge, calm your mind, de-rust,
                      de-stress, re-wire. Graceful body, bright mind. No
                      experience necessary. At the moment the focus is Conscious
                      Anatomy™Movement. See{" "}
                      {/* <a
                        href="/classes"
                        // href="http://www.newharmonymassage.com/classes.htm#consciousanatomy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        See our listing in classes for more Conscious Anatomy™
                        then scroll down to view Movement for Body Mind Spirit™.
                      </a>{" "} */}
                      <a
                        href="https://www.bodymindspiritanatomy.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.bodymindspiritanatomy.com/
                      </a>{" "}
                      I am currently not offering Movement for Body Mind and
                      Spirit but may in the future. Stay tuned.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <strong>8. Teens and Children</strong>
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      When working with teens and children, the sessions are
                      usually shorter and the kids wear a bathing suit or shorts
                      and an undershirt or something similar. It tends to take
                      fewer sessions because even if the problem is systemic it
                      is not deeply ingrained. This does not necessarily apply
                      if there are mitigating problems, like CP or MS or other
                      such problems, although Reactive Triads™ Body Work can be
                      correctional for scoliosis and is very helpful in the
                      cases of Cerebral Palsy and Multiple Sclerosis. Parents
                      may stay in the room.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingNine">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    <strong>9. Deep Relaxation / The Zone</strong>
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Deep Relaxation / The Zone We have often had people fall
                      asleep on the table or enter into the realm of the Zone –
                      entering a place of deep relaxation but being cognizant at
                      the same time- like a daytime dream state; it is a place
                      of deep integration yet there remains the ability to
                      follow directions as needed with the muscle balancing work
                      that we do. This is an amazing place to be in and is
                      exceptionally healing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    <strong>
                      10. Somatic Integration with Reactive Triads™
                    </strong>
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Somatic Integration in our bodywork is based on the
                      assumption that our muscles carry past memories or beliefs
                      about ourselves. When deep-seated muscle tension is
                      released, muscle memories or beliefs about ourselves can
                      surface in association with the chronic pain or tension we
                      are working to resolve. For complete healing to occur,
                      rather than entertain story or judgement associated with
                      it, what seems to work best is to feel into the tension
                      and let it dissolve, integrate and, if pertinent, choose
                      to maintain or change whatever belief we've been carrying.
                      Through pain relief and postural correction, what we've
                      sequestered in our muscles from old traumas may surface,
                      but resolve with corrective techniques which integrate
                      into new awareness, new understandings and a more
                      comfortable in-your-body experience. Occasionally, a
                      "healing crisis" might occur: a flooding of old issues and
                      associated physical pain for a limited time as your body
                      integrates the plethora of new information and insights.
                      It might be important to be aware of your balance if your
                      body needs more time than the session to get used to the
                      new muscle patterns being introduced and to integrate the
                      somatic information..
                      <a
                        href="/therapies"
                        // href="http://www.newharmonymassage.com/therapies.htm#3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (see Reactive Triads).
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingEleven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    <strong>11. Visceral Manipulation</strong>
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Visceral Manipulation is a very gentle technique developed
                      by Jacques Pierre Barrel. We listen to the tissues, the
                      internal organs, as well as other body structures so they
                      can track more properly and freely. Our bodies are meant
                      to move and accommodate from the inside out. When tissues
                      get sticky due to illness, accidents, injury the tissues
                      can stop sliding over one another the way that they are
                      meant to and the internal organs become limited in their
                      intended movement. We hold the tissue in the direction of
                      ease or intention so that the body can make an appropriate
                      shift and free up tissue to move as it was meant to. Great
                      for your health and well being and helpful for many
                      maladies. Combined as needed with Reactive Triads™
                      bodywork.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwelve">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    <strong>12. Classes: New offerings every Fall</strong>
                  </button>
                </h2>
                <div
                  id="collapseTwelve"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwelve"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      <a href="/classes" target="_blank" rel="noreferrer">
                        {" "}
                        Class Offerings
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThirteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    <strong>13. Swedish Massage</strong>
                  </button>
                </h2>
                <div
                  id="collapseThirteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      One of the most common forms of massage, Swedish relaxes
                      the muscles and eases aches and pains. It is always
                      integrated into our form bodywork which specializes in
                      postural correction and pain relief.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFourteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <strong>14. Sports Massage</strong>
                  </button>
                </h2>
                <div
                  id="collapseFourteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFourteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Developed specifically to treat the athlete, sports
                      massage techniques are utilized at all stages of an event:
                      pre-event to bring health and wellness to the tissues;
                      post- event to enhance recovery; during training to
                      maintain the muscles and enable peak performance and, at
                      any appropriate point, to expedite rehabilitation after
                      injury. Our practitioners may utilize sports massage
                      techniques to facilitate results during a session.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFifteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFifteen"
                    aria-expanded="false"
                    aria-controls="collapseFifteen"
                  >
                    <strong>15. Deep Tissue</strong>
                  </button>
                </h2>
                <div
                  id="collapseFifteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFifteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      A technique that works slowly and deeply through the
                      layers of muscle and fascia, it lengthens tight or
                      shortened muscles, stretches scar tissue and releases
                      adhesions that have resulted from trauma, injury,
                      operations, or repetitive strain. This work helps to
                      improve posture, range of motion, function, and strength
                      and is typically integrated into our sessions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSixteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSixteen"
                    aria-expanded="false"
                    aria-controls="collapseSixteen"
                  >
                    <strong>16. Spinal Postural Stabilization</strong>
                  </button>
                </h2>
                <div
                  id="collapseSixteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSixteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      We may give you exercises that increase strength,
                      mobility, endurance, coordination, and balance. As the
                      stabilization of your back increases, movement without
                      pain is supported and your pain is much more likely to
                      “just go away”.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSeventeen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeventeen"
                    aria-expanded="false"
                    aria-controls="collapseSeventeen"
                  >
                    <strong>17. Acupressure</strong>
                  </button>
                </h2>
                <div
                  id="collapseSeventeen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeventeen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Granddaughter to Shiatsu, Acu means finger. In Acupressure
                      two points are held until they begin to pulse consistently
                      and strongly together. This tunes up your energy body
                      system and relieves pain and discomfort. Unlike Shiatsu,
                      it doesn’t necessarily follow a meridian. In fact, it
                      tends to cross meridians, characterized by “strange
                      flows”. Can be very effective in relieving chronic
                      problems involving an energy component. May be
                      incorporated into a session. We do not specialize in this
                      and encourage you to seek elsewhere if this is what you
                      are specifically interested in.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingEighteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEighteen"
                    aria-expanded="false"
                    aria-controls="collapseEighteen"
                  >
                    <strong>18. Shiatsu</strong>
                  </button>
                </h2>
                <div
                  id="collapseEighteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEighteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Another way to say finger pressure, Shiatsu has a long
                      history in the Orient. Using various digits, the
                      practitioner follows the the body’s meridians (the
                      physical lines of the energetic body) using thumbs
                      frequently or fingers as needed. Like Acupressure it tunes
                      up the energy system and can be used at various depths to
                      the muscle to create a strong pressure, a medium pressure,
                      or a light pressure depending on what your body calls for.
                      Older people especially find it to be very grounding and
                      centering when other bodywork leaves them feeling
                      disoriented. May be incorporated into a session and
                      occasionally may comprise the bulk of a session but it is
                      not what we specialize in and we therefore encourage you
                      to seek elsewhere if this is what you are specifically
                      interested in.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingNineteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNineteen"
                    aria-expanded="false"
                    aria-controls="collapseNineteen"
                  >
                    <strong>19. Polarity</strong>
                  </button>
                </h2>
                <div
                  id="collapseNineteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNineteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Polarity is also an energy work that uses finger pressure.
                      Essentially, it locates two correspondingly sore points on
                      the body and uses alternate circular motion to relieve the
                      knots and muscle discomfort. This is the part of polarity
                      we tend to incorporate into our sessions. It is a much
                      more extensive practice than just that but it is not
                      something we specialize in. We encourage you to look
                      elsewhere if that is what you are specifically interested
                      in.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwenty">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwenty"
                    aria-expanded="false"
                    aria-controls="collapseTwenty"
                  >
                    <strong>20. Conscious Anatomy™</strong>
                  </button>
                </h2>
                <div
                  id="collapseTwenty"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwenty"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Foundation courses: learn anatomy through movement with
                      somatic awareness and energetic attunement. Advanced
                      courses in ReactiveTriads™ Bodywork.
                      <a
                        href="/classes"
                        // href="http://www.newharmonymassage.com/classes.htm#consciousanatomy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Class Listings{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Therapies;
