// import { Button } from "bootstrap";
import * as React from "react";

import "./routes.css";
import annsRoom from "../img/annsroom.jpg";
import annsRoomLg from "../img/annsroom_lg.jpg";
import staffsRoom from "../img/staffsroom.jpg";
import staffsRoomLg from "../img/staffsroom_lg.jpg";
import reactiveTriadsDvd from "../img/reactiveTriadsDvd.jpg";

const greenStudioTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
};

const greenStudioBackground = {
  backgroundColor: "#D0C88E",
};
const greenStudioContentBackground = {
  backgroundColor: "#E9E4D2",
};
const aboutText = {
  padding: ".5rem",
  marginLeft: "1rem",
};
const contactAnn = {
  // height: "35vh",
  width: "10vw",
  borderRadius: "5px",
  boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
  // marginLeft: "1rem",
};
const roomImg = {
  // height: "35vh",
  width: "10vw",
  borderRadius: "5px",
  boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
  marginLeft: "3.75rem",
  // text: "center",
};
// const aboutFinalP = {
//   padding: ".5rem",
//   marginLeft: "1rem",
//   marginBottom: "0rem",
// };
function GreenStudio() {
  return (
    <>
      <div style={greenStudioBackground}>
        <div className="container">
          <div style={greenStudioContentBackground}>
            <h1 style={greenStudioTitles}>Green Studio</h1>

            <h5 style={aboutText}>
              <small>
                Our Green Studio We’re all about health and what better way to
                support it than in a studio that is in harmony with the
                environment and low energy use; completely nontoxic insulation;
                radiant floor heat and hot water from solar panels.
              </small>
            </h5>
            <p style={aboutText}>
              We believe in green business and taking care of the environment as
              a way to improve the quality of life on this planet and the health
              of all of us. We’re doing our best to do our share by recycling,
              heating and lighting with as much renewable energy as we possibly
              can and using non-toxic materials, including the insulation in our
              building. The majority of our heat is provided by solar panels on
              our roof, which heat the water, which runs through our radiant
              floor heating system. We have skylights in our airy, cathedral
              ceiling studios to provide passive heat and light. Very cozy and
              warm in the winter, bamboo shades and foliage provide passive
              cooling in the summer. Please be fragrance free in our environment
              as some of our clients are exceptionally sensitive.
            </p>

            <h5 style={aboutText}>
              <strong>Our Waiting Room </strong> <br />
            </h5>
            {/* Instead of Lists I can use a Button attached to a Modal */}
            <div className="container">
              <ol>
                <li>
                  Small store with: Cds, DvDs and Wellness Items also accessible
                  online:{" "}
                  <a href="/store" target="_blank" rel="noreferrer">
                    Store
                  </a>{" "}
                </li>
                <li>
                  Small Store Offering: <strong>Cds</strong>,{" "}
                  <strong>DvDs</strong>, and <strong>Wellness Items</strong> -
                  Accessible Online:{" "}
                  <a href="/store" target="_blank" rel="noreferrer">
                    Store
                  </a>
                </li>
                <li>Variety of Complementary Tea</li>
                <li>A Futon for the Limber & a Bench for firm Seating</li>
              </ol>
            </div>

            <h5 style={aboutText}>
              <strong>Our Entrance: Semi Accessible</strong> <br />
            </h5>
            <div className="container">
              <ol>
                <li>The walkway consists of sections three to 15 feet long</li>
                <li>
                  The steps rise only six inches each from the driveway to the
                  entrance
                </li>
                <li>
                  {" "}
                  There are a total of seven steps to the door (pix of walkway){" "}
                </li>
                <li>
                  Please let us know if you have special needs. We will do our
                  best to make it accessible for you
                </li>
              </ol>
            </div>

            <div className="row">
              <div className="col-6">
                <h3 style={greenStudioTitles}>Ann's Room</h3>
                <h5 style={aboutText}>
                  {" "}
                  <small>Click to Enlarge</small>
                </h5>
                <a href={annsRoomLg} target="_blank" rel="noreferrer">
                  <img src={annsRoom} style={roomImg} alt="ann's room" />
                </a>
              </div>

              <div className="col-6">
                <h3 style={greenStudioTitles}>Staff's Room</h3>
                <h5 style={aboutText}>
                  {" "}
                  <small>Click to Enlarge</small>
                </h5>
                <div>
                  <a href={staffsRoomLg} target="_blank" rel="noreferrer">
                    <img src={staffsRoom} style={roomImg} alt="staff's room" />
                  </a>
                </div>
              </div>
            </div>

            <div>
              <h3 style={greenStudioTitles}>Reactive Triads™ 2 Disc DVD ››</h3>

              <div style={{ textAlign: "center" }}>
                <img
                  src={reactiveTriadsDvd}
                  style={contactAnn}
                  alt="Reactive Triads DvD"
                />
              </div>

              <div style={aboutText}>
                New Harmony Therapeutic Massage and Somatic Body Work 2006
                Reactive Triads™: A Breakthrough in Chronic Pain Relief
                documents Ann Watkins’ original body work. It consists of three
                elements that when activated in the body causes a muscle to
                misfire. The result is muscle pain, nerve pain, limited range of
                motion, stiffness, and postural problems. Integrating lymphatic
                drainage and the joint system, Reactive Triads™ is exceptionally
                effective in solving chronic pain problems due to muscle
                imbalance caused by injury, whiplash, repetitive motion, stress
                and the general insults life brings. Note: This DVD is a great
                supplement to taking Ann’s courses. Please check out{" "}
                <a
                  href="https://www.bodymindspiritanatomy.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  bodymindspiritanatomy.com
                </a>{" "}
                for more information.
                <a
                  href="https://www.bodymindspiritanatomy.com/offers/tEMaQJqu/checkout"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5>
                    <small>
                      <strong>$42 Reactive Triads™ 2 Disc DVD ››</strong>
                    </small>
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GreenStudio;
