import React, { useEffect } from 'react';

const GiftCertificate = () => {
  useEffect(() => {
    if (window.paypal) {
      window.paypal.HostedButtons({
        hostedButtonId: 'ACKSY7R7CT45Y',
      }).render('#paypal-container-ACKSY7R7CT45Y');
    }
  }, []);

  return (
    <div>
      <div id="paypal-container-ACKSY7R7CT45Y"></div>
    </div>
  );
};

export default GiftCertificate;