import * as React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import bbbLogo from "./img/bbbLogo.png";
import NCBTMB from "./img/NCBTMB.png";

const footer = {
  display: "flex",
  background: "#5E87A1",
  // backgroundImage: url(/Img/navBackImg3.jpg),
  // backgroundImage: `url(${background})`,
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  padding: "1rem",
};

const bbb = {
  width: "25rem",
  height: "auto",
};

const NationalCBTMB = {
  width: "10rem",
  height: "auto",
  borderRadius: "2rem",
  boxShadow: "0 3px 10px rgb(0 0 0)",
};

export default function Footer() {
  return (
    <div style={footer}>
      <a href="https://www.credly.com/badges/7d198906-aa88-4665-8fe7-fa26f5a05044/email">
        <img src={NCBTMB} style={NationalCBTMB} alt="NCBTMB Logo" />
      </a>
      <a href="https://www.bbb.org/">
        <img src={bbbLogo} style={bbb} alt="betterBusinessBureauLogo" />
      </a>
      <p style={{ color: "#fff", marginBottom: "1rem" }}>
        By Appointment Only | Pawcatuck CT 06379 |{" "}
        <Link to="tel:8602871409" className="footerLinks">
          <FontAwesomeIcon icon={faPhone} /> (860) 599.4533
        </Link>
      </p>
      <p style={{ color: "#FFFFFF", marginTop: "1rem" }}>
        &copy; 2022-{new Date().getFullYear()} | New Harmony Therapeutic Massage
        and Somatic Bodywork
        <br />
        All Right Reserved |{" "}
        <Link to="/contact" className="footerLinks">
          <span> Contact |</span>
        </Link>
        <Link to="/terms" className="footerLinks">
          <span> Terms of Service |</span>
        </Link>
        <Link to="/privacypolicy" className="footerLinks">
          <span> Privacy Policy |</span>
        </Link>
        <a href="https://ubitek.io/" className="footerLinks">
          <span> Built By: UbiTek</span>
        </a>
      </p>
    </div>
  );
}
