import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import "./App.css";

const navLink = {
  color: "white",
  textDecoration: "none",
};

export default function App() {
  return (
    <div>
      <Header />

      <nav
        style={{
          // color: 'white',
          borderBottom: "solid 1px",
          paddingBottom: ".5rem",
          paddingTop: ".5rem",
          textAlign: "center",
          backgroundColor: "#7A903E",
          // backgroundColor: "#65903E",
        }}
      >
        <Link style={navLink} to="/">
          Home
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/about">
          About
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/therapies">
          Therapies
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/greenStudio">
          Green Studio
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/classes">
          Classes
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/aMomentForYourself">
          A Moment For Yourself
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/store">
          Store
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/faq">
          FAQ
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/testimonials">
          Testimonials
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/resources">
          Resources
        </Link>{" "}
        |{" "}
        {/* <Link style={navLink} to="https://newharmonymassage.weebly.com/"> */}
        <Link style={navLink} to="/blog">
          Blog
        </Link>{" "}
        |{" "}
        <Link style={navLink} to="/contact">
          Contact
        </Link>
      </nav>
      <Outlet />
      <Footer />
    </div>
  );
}
