import newHarmonyHeader from "./img/newHarmonyHeader.jpg";
import { Link } from "react-router-dom";

const headerStyle = {
  backgroundColor: "#37659D",
  flexWrap: "nowrap",
  display: "flex",
  justifyContent: "center",
};

const logoStyle = {
  maxHeight: "8.2vh",
  height: "100%",
  width: "100%",
  cursor: "pointer",
};

function Header() {
  return (
    <Link to="/">
      <div style={headerStyle}>
        <div>
          <img src={newHarmonyHeader} alt="Logo" style={logoStyle} />
        </div>
      </div>
    </Link>
  );
}

export default Header;
