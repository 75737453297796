// import { Button } from "bootstrap";
import * as React from "react";
import Ann from "../img/ann.jpg";
// import whiteLotus from "../img/whiteLotus.jpeg";
import pinkLotusBackground from "../img/pinkLotusBackground.jpeg";
import "./routes.css";

function Contact() {
  const contactStyle = {
    marginLeft: "1.25vw",
    marginRight: "1.25vw",
    padding: "5rem",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  };
  const contactAnn = {
    width: "10vw",
    borderRadius: "5px",
    boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
  };
  const contactTitle = {
    textAlign: "center",
    padding: "3rem",
    marginTop: "0rem",
    color: "#B46000",
  };
  const background = {
    backgroundImage: `url(${pinkLotusBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
    backgroundPosition: "center",
  };
  const textStyle = {
    color: "#B46000",
    textDecoration: "none",
  };

  return (
    <>
      <div style={background}>
        {/* <h1 style={contactTitle}>Contact</h1> */}
        <h4 style={contactTitle}>New Harmony Massage & Somatic Bodywork</h4>

        <h4 style={contactTitle}>13 River Crest Drive Pawcatuck, CT 06379</h4>
        <div className="row" style={contactStyle}>
          <div className="col-md-4">
            <img src={Ann} style={contactAnn} alt="nate" />
            <em>
              <h4 style={textStyle}>Ann Watkins</h4>
            </em>
            <a
              href="tel:(860) 599.4533"
              target="_blank"
              rel="noreferrer"
              style={textStyle}
            >
              <p>860.599.4533</p>
            </a>
            <a
              href="mailto:bodymindspiritanatomy@gmail.com"
              target="_blank"
              rel="noreferrer"
              style={textStyle}
            >
              <p>bodymindspiritanatomy@gmail.com</p>
            </a>
            <p style={textStyle}>Visits are by appointment only.</p>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
}
export default Contact;
