import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Routes
import About from "./components/routes/about";
import Therapies from "./components/routes/therapies";
import GreenStudio from "./components/routes/greenStudio";
import Classes from "./components/routes/classes";
import AMomentForYourself from "./components/routes/aMomentForYourself";
import Store from "./components/routes/store";
import FAQ from "./components/routes/faq";
import Testimonials from "./components/routes/testimonials";
import Resources from "./components/routes/resources";
import Blog from "./components/routes/blog";
import Contact from "./components/routes/contact";
import Home from "./components/routes/home";
// Routes

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// import $ from "jquery";
// import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
// Bootstrap

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="therapies" element={<Therapies />} />
          <Route path="greenStudio" element={<GreenStudio />} />
          <Route path="classes" element={<Classes />} />
          <Route path="aMomentForYourself" element={<AMomentForYourself />} />
          <Route path="store" element={<Store />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="resources" element={<Resources />} />
          <Route path="blog" element={<Blog />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
