// import { Button } from "bootstrap";
import * as React from "react";
import "./routes.css";

const faqBackground = {
  backgroundColor: "#D0C88E",
  // backgroundColor: "#37659D",
  padding: "1rem",
};
const faqContentBackground = {
  backgroundColor: "#E9E4D2",
};
const faqTitles = {
  color: "#B46000",
  padding: ".5rem",
  marginLeft: "1rem",
  paddingTop: "1rem",
  textAlign: "center",
};
const faqAccordion = {
  padding: ".5rem",
};

function FAQ() {
  return (
    <>
      <div style={faqBackground}>
        <div style={faqContentBackground}>
          <h2 style={faqTitles}>Frequently Asked Questions</h2>
          <div className="container">
            <div
              className="accordion"
              id="accordionExample"
              style={faqAccordion}
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Q: If I'm in pain, how many sessions will it take to
                    resolve?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      <strong>
                        That depends on whether your problem is simple or
                        systemic.
                      </strong>
                    </p>
                    <p>
                      Chronic pain is usually systemic. If it isn’t systemic,
                      one session can be enough, with one or two follow up
                      sessions if needed. If the problem is systemic, it will
                      require reprogramming various layers of malfunctioning
                      muscle patterns which can take varying lengths of time.
                    </p>

                    <p>
                      The human body is connected from head to toe in fascial
                      tissue - a thin tissue that surrounds every single muscle
                      creating a web through the whole body. To understand what
                      happens when muscle patterns aren’t functioning properly,
                      imagine a tangled ball of yarn or a thin, silver, link
                      necklace that someone didn’t put away at all carefully. To
                      unravel the yarn or the necklace (ie unknot the muscle),
                      you have to start with what’s accessible. Sometimes the
                      knots are easy, sometimes not. Or, you run into a series
                      of knots. A second knot may be worse than a first knot.
                      There are times when everything unravels easily and
                      quickly – a miracle session which may resolve the problem
                      or, sometimes, uncover more to chip away at. When patterns
                      unravel easily, you’re body is in less pain. When the
                      origin of the problem is resolved, muscle patterns hold
                      and pain is relieved (ie you have useable yarn, a useable
                      necklace). Patterns also hold along the way and pain is
                      reduced even if it is clear there is more to do.
                    </p>
                    <p>
                      In short, the process of healing can be quick, or it can
                      be long, depending on the condition of your body. It
                      didn’t get that way over night; and the problem may not be
                      resolved over night.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Q: How often do I need to come?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Though this it isn’t always possible, we like to start with
                    three sessions in three weeks to get ahead of the problem.
                    We may continue on at once a week for awhile or, if things
                    are progressing well, we will push the sessions out to every
                    other week, then every three weeks, once a month, etc. We
                    determine together whether we can put more time in between
                    sessions or if there is too much time between sessions, and
                    we back up to less if needed. We work with you to discover
                    what the best schedule for your body is and we work around
                    financial issues as well.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Q: Should I come to sessions for the rest of my life?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      <strong>That depends on your goals.</strong>
                    </p>
                    <p>
                      First of all, you have to determine what your goal is. We
                      have people who come once a week. We have people who come
                      every other week, every three weeks, once a month, a few
                      times a year. We have people who come in, get the problem
                      resolved and don’t come back unless they get into trouble
                      or occasionally come in as a treat to themselves.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Q: What can I expect from this bodywork?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      This bodywork is excellent for regaining and maintaining
                      your muscle health and for resolving problems before they
                      start “talking” to you. Muscles can be sore and you won’t
                      know it until you’re getting worked on. We’ve had people
                      come in saying they feel great. They leave saying: I
                      thought I felt good when I came in; now I feel Grrr eeaat!
                      So, it depends on your expectation and we stay in
                      communication about your goals. Things change and goals
                      may too.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Q: Is there anything else I should know?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      In many ways, bodywork is like exercise, i.e. getting into
                      shape. You can’t go to one exercise class and come out
                      buff. You don’t take one exercise class and decide that
                      that should do it for the rest of your life. Exercise is a
                      process. It takes time to get the result you’re looking
                      for. It takes time to maintain the result. You can stop at
                      different points and not lose too much ground, depending
                      on what else you’re doing in you life, but, if you stop
                      exercising, you will start to lose that tone down the
                      road. If you strike a balance and get a decent amount of
                      exercise, then, to the degree that you want, you can
                      maintain being in shape. Bodywork isn’t so different. It
                      is a process. You’re healthier with it than without it.
                      It’s a quality of life choice more than anything else,
                      whether you’re in pain or not. And it plays into how
                      healthy and resilient you’ll be throughout your life.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Q: Will it take care of all my chronic aches and pains?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Not necessarily, but if you have a chronic underlying
                      problem that prevents full resolution of the muscle pain
                      problem, then this bodywork could be a way to help keep it
                      under control. The choice of how much bodywork you receive
                      is always a personal choice, along with any mitigating
                      factors; it is based on your personal goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Q: What might I experience following a session?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      If you have a lot of puffiness in your tissues, your body
                      needs to reabsorb it and get rid of it through your
                      excretory system. You may be sore after the session
                      because of this and won’t feel the full benefit of the
                      work until later that evening or in the next day or two
                      because proper muscle contraction is what moves these
                      fluids out through activation of your lymphatic system.
                      Your body has to have time to integrate the work, contract
                      the muscles properly, and move the excess fluid out. This
                      is where the soreness tends to come from. It is not always
                      a part of the process and it typically doesn’t follow
                      every session, but it is important to be aware of this as
                      a possibility during your healing process. Drinking lots
                      of water can help as well as Epsom salt baths.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Q: Will I be sore after the session?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      People often feel great after a session and don’t
                      experience soreness. And we’ve had people feel sore for
                      the next day or two- just as you would after taking an
                      exercise class your body isn’t used to. We recommend
                      taking an Epsom salts bath, drinking lots of water and
                      planning your session so you don’t have anything very
                      demanding you have to do afterwards.
                    </p>
                    <p>
                      Being sore after one session doesn’t mean you won’t feel
                      great after your next session or a lot less sore. It is
                      entirely dependent on what muscle patterns are resolvable
                      in that session, the relative health of the particular
                      muscles we’ve worked with and how much those muscle are
                      used to being taxed during daily activities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingNine">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Q: What types of therapies might be used during a session?
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      During a Body work session: Reactive Triads™Bodywork (with
                      neuromuscular reprogramming-which it is based in);
                      Lymphatic Drainage, brain work as part of lymph drainage,
                      Visceral, nerve work, energy Work including Polarity,
                      Shiatsu, Acupressure, Reiki, Somatic Awareness and
                      Energetic Attunement; Deep Tissue; Sports Massage;
                      Swedish. During a Movement Session: Yoga, Dance Movement
                      Therapy, Contemplative Movement, Interplay may be
                      integrated in any class or individual session.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FAQ;
