import * as React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Ann from "../img/annWatkins.jpg";
// import Blog from "../img/blog.gif";
// import ReactiveTriadsLogo from "../img/reactive-triads-bodywork-logo.jpg";
import greenReactiveTriadsBodyworkLogo from "../img/greenReactiveTriadsBodyworkLogo.png";
import whiteLotus2 from "../img/whiteLotus2.jpeg";
import midflower75x82 from "../img/midflower75x82.jpg";
import flower from "../img/flower.jpg";
import "./routes.css";

const homeBackground = {
  backgroundColor: "#37659D",
  // justifyContent: "center",
};
const parallaxHome = {
  backgroundImage: `url(${whiteLotus2})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "100vh",
  // width: "100vw",
};
const homeLinks = {
  textAlign: "center",
  color: "#FFFFFF",
  // backgroundColor: "rgba(0, 0, 0, 0.5)",
  opacity: 0.9,
  textDecoration: "none",
  // marginLeft: "10px",
  // position: "fixed",
  // width: "50rem",
  // height: "20rem",
  // boxSizing: "border-box",
};
const homeBtn = {
  margin: "1rem",
  border: "1px solid white",
  // backgroundColor: "#000"
  backgroundColor: "#7a903e",
};
const cardImg = {
  // textAlign: "center",
  padding: ".25rem",
  width: "97px",
  height: "122px",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "1rem",
};
const midFlower = {
  width: "3vw",
  height: "5vh",
  borderRadius: "1rem",
};
const cardText = {
  color: "#FFFFFF",
};
const homeFlower = {
  // borderRadius: "1rem",
  width: "100%",
};

function Home() {
  return (
    <>
      <div style={homeBackground} className="pb-3">
        <div className="jumbotron section" style={parallaxHome}>


          <div className="container" style={homeLinks}>
            <Button style={homeBtn}>
              <a
                style={homeLinks}
                href="https://www.bodymindspiritanatomy.com/offers/W8nmNqBL/checkout"
                target="_blank"
                rel="noreferrer"
              >
                <p>Free Self Care 7 Week Mini Chakra Series</p>
              </a>
            </Button>
            {/* <br /> */}

            <Button style={homeBtn}>
              <a
                style={homeLinks}
                href="https://www.youtube.com/watch?v=NYKKulvYOeU"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  Become a Sponge for Anatomical Terms and Concepts Through Self
                  Care with Conscious Anatomy™
                </p>
              </a>
            </Button>
            {/* <br /> */}

            <Button style={homeBtn} className="btn btn-outline">
              <a
                style={homeLinks}
                href="https://www.bodymindspiritanatomy.com/"
                target="_blank"
                rel="noreferrer"
              >
                <p>Purchase Training</p>
              </a>
            </Button>
            {/* <Button style={homeBtn}>
              <a
                style={homeLinks}
                href="https://consciousanatomyengland.netlify.app"
                target="_blank"
                rel="noreferrer"
              >
                <h5>ENGLAND!!!!!</h5>
              </a>
            </Button> */}
          </div>


        </div>

        <img src={flower} style={homeFlower} alt="home banner" />

        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  className="reactivetriadsCardTopBackgroundColor"
                  variant="top"
                  src={greenReactiveTriadsBodyworkLogo}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>
                      Reactive Triads™ 2 Disc DVD New Harmony Therapeutic
                      Massage and Somatic Bodywork
                    </strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    Reactive Triads™ are three elements activated in the body
                    that cause a muscle to switch off resulting in muscle pain,
                    nerve pain, limited range of motion. Integrating lymphatic
                    drainage and jolts, Reactive Triads™ are exceptionally
                    effective in solving chronic pain problems due to muscle
                    imbalance caused by injury, whiplash, repetitive motion and
                    stress.
                  </Card.Text>
                  {/* This button will be eventually linked to the http://localhost:3000/store#reactivetriadsdvd section via an ID + href  */}

                  <Link
                    to="/store"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Read Details ››</Button>
                  </Link>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img style={cardImg} variant="top" src={Ann} />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>Ann Watkins, MA Licensed Massage Therapist</strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    Honors in Psychology, MA in Dance Movement Therapy, Licensed
                    Massage Therapist since 1988, published in Massage Magazine.
                    Trained in Lymphatic Drainage; Energy Work: shiatsu,
                    acupressure, polarity; Neuro Muscular Reprogramming Therapy;
                    Developer of Reactive Triads™ -a breakthrough in chronic
                    pain relief (DVD). LMT # 000393
                  </Card.Text>
                  {/* This button will be eventually linked to the http://localhost:3000/about#annwatkins section via an ID + href  */}
                  <a
                    href="/about"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button> Read Details ››</Button>
                  </a>{" "}
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  className="reactivetriadsCardTopBackgroundColor"
                  variant="top"
                  src={greenReactiveTriadsBodyworkLogo}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>Reactive Triads™</strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    <p>
                      Reactive Triads™ (Neuro) Reactive Triads™ classes coming
                      up. Postural corrective bodywork advanced principals based
                      in NMR/NKT
                    </p>
                    {/* This button will be eventually linked to the http://localhost:3000/classes#reactivetriads section via an ID + href  */}
                    <a
                      href="https://www.bodymindspiritanatomy.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button>Read details and Register ››</Button>
                    </a>
                    <p style={cardText}></p>
                    <p style={cardText}>
                      <strong>Chronic Pain Breakthrough!</strong>
                      <p>
                        Acute and Chronic Pain Relief Postural Correction
                        Increased Range of Motion Mind - Body - Spirit
                        Integration.
                      </p>
                    </p>
                  </Card.Text>
                  {/* This button will be eventually linked to the http://localhost:3000/about#chronicpainbreakthrough section via an ID + href  */}
                  <a
                    href="/about"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Read details ››</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  variant="top"
                  src={midflower75x82}
                  style={midFlower}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>Supervision & Mentoring</strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    Classes are ongoing and we are available to help you build a
                    more effectiveness practice and greater confidence in your
                    work through Supervision. We can be anywhere and still be in
                    touch by phone. We can answer your client questions and
                    provide you with helpful guidance- from solidly building
                    your practice to solving problems you are stumped by in your
                    sessions. We have a very successful practice going on 20+
                    years with thousands of hours of experience.
                  </Card.Text>
                  {/* This button will be eventually linked to the http://localhost:3000/classes#supervisionmentoring section via an ID + href  */}
                  <a
                    href="/classes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Read details and Register ››</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  variant="top"
                  src={midflower75x82}
                  style={midFlower}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>A Moment for Yourself</strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    <p>
                      Click to receive our very popular
                      <a
                        href="https://newharmonymassage.weebly.com/a-moment-for-yourself.html"
                      >
                        <Button>"A Moment for Yourself Chakra Series"</Button>
                      </a>
                    </p>
                    <p>
                      We have various FREE resources in our on-line library at
                      our Conscious Anatomy™ site. Boosting Your Creativity and
                      Your Calm (ten minute audio meditation by Ann Watkins),
                      sample classes and other resources will become available
                      to you at the Conscious Anatomy™site following the
                      conclusion of your "A Moment for Yourself " Chakra Series.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  variant="top"
                  src={midflower75x82}
                  style={midFlower}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>
                      A Moment for Yourself Archives - Please Share your
                      Thoughts with Us
                    </strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    We've made discoveries and have ideas for feeling better and
                    being healthier and more vibrant! We'd love to hear from
                    you. Send us your comments, suggestions, testimonials,
                    thoughts. And let us know if you are interested in hearing
                    about upcoming classes and offerings, sign up top right on
                    our home page!
                  </Card.Text>
                  <a
                    href="/aMomentForYourself"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Read details ››</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  variant="top"
                  src={midflower75x82}
                  style={midFlower}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>
                      New Harmony Therapeutic Massage and Somatic Bodywork
                    </strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    The original, advanced postural corrective bodywork
                    principals by Ann Watkins based in NMR/NKT. Reactive Triads™
                    (Neuro) Reactive Triads™, a breakthrough in chronic pain
                    relief.
                  </Card.Text>
                  <a
                    href="/about"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Read details ››</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  variant="top"
                  src={midflower75x82}
                  style={midFlower}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>
                      What's Happening at New Harmony? Check out our blog!
                    </strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    Learn about our new staff. Stay informed about upcoming
                    events and look here for class dates and trainings.
                  </Card.Text>
                  <a
                    href="https://newharmonymassage.weebly.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Read Blog</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 p-1">
              <Card
                style={{
                  width: "23rem",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 5px 5px",
                  height: "auto",
                  backgroundColor: "#37659D",
                }}
              >
                <Card.Img
                  variant="top"
                  src={midflower75x82}
                  style={midFlower}
                />
                <Card.Body>
                  <Card.Title style={cardText}>
                    <strong>Store</strong>
                  </Card.Title>
                  <Card.Text style={cardText}>
                    Visit our secure online store Find gift certificates and all
                    sorts of great items.
                  </Card.Text>
                  <a
                    href="/store"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Store ››</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
